<template>
  <div class="container">
    <el-card class="box-card">
      <el-button size="mini" style="margin-bottom:10px;" @click="$router.go(-1)">返回</el-button>
      <el-descriptions title="订单信息">
        <el-descriptions-item label="订单号">{{ order.orderNum }}</el-descriptions-item>
        <el-descriptions-item label="	快递单号">{{ order.expressNum }}</el-descriptions-item>
        <el-descriptions-item label="商品项数量">{{ order.productCount }}</el-descriptions-item>
        <el-descriptions-item label="商品总价">{{ order.productTotalPrice }}</el-descriptions-item>
        <el-descriptions-item label="实际付款金额">{{ order.payMoney }}</el-descriptions-item>
        <el-descriptions-item label="	付款时间">{{ order.payTime }}</el-descriptions-item>
        <el-descriptions-item label="付款类型">{{ order.payType == 0 ? '微信支付' : '其他支付' }}</el-descriptions-item>
        <el-descriptions-item label="	收货人">{{ order.takeName }}</el-descriptions-item>
        <el-descriptions-item label="收货人手机号码">{{ order.takeMobile }}</el-descriptions-item>
        <el-descriptions-item label="收货地址">{{ order.takeAddress }}</el-descriptions-item>
        <el-descriptions-item label="快递类型">{{ order.expressName }}</el-descriptions-item>
        <el-descriptions-item label="	第三方支付流水号">{{ order.outTradeNo }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">
          <el-tag :type="colorStatus[order.status]">{{ status[order.status] }}</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <h4>订单详情</h4>
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column align="center" label="商品名称" prop="productName"></el-table-column>
        <el-table-column align="center" label="商品单价" prop="productPrice" width="130px"></el-table-column>
        <el-table-column align="center" label="商品优惠价" prop="productDiscountPrice" width="130px"></el-table-column>
        <el-table-column align="center" label="商品数量" prop="productCount" width="130px"></el-table-column>
        <el-table-column align="center" label="商品总计金额" prop="productTotalMoney" width="150px"></el-table-column>
        <el-table-column align="center" label="商品类型" width="130px">
          <template slot-scope="scope">
            <el-tag :type="typeStatusColor[scope.row.productType]">{{ typeStatus[scope.row.productType] }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import {OrdersItme, getOrderNumber} from "../../apis/order.js";
import {status, colorStatus, typeStatus, typeStatusColor} from './index.js'

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      order: {},
      status: status,
      colorStatus: colorStatus,
      typeStatus: typeStatus,
      typeStatusColor: typeStatusColor
    };
  },
  activated() {
    this.getOrderInfo(this.$route.query.orderNum)
    this.getOrder(this.$route.query.orderNum)
  },
  methods: {
    async getOrderInfo(orderNum) {
      const res = await OrdersItme({orderNum});
      this.tableData = res.data
    },
    async getOrder(orderNum) {
      const res = await getOrderNumber({orderNum})
      this.order = res.data
    }
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-descriptions-item__label:not(.is-bordered-label) {
  align-items: center !important;
}
</style>